@import '../../scss/theme.scss';

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #124659;
  color: $white;

  .subtitle {
    color: $white;
  }

  a {
    color: $yellow;
  }
  a.btn.btn-custom {
    background-color: #FED367;
    color: #0B2B37;
    text-decoration: none;
  }

  .dropdown{
    a {
      color: $black;
    }
  }

  button {
    background-color: #FED367;
    color: #0B2B37;
  }
  button.close{
    background-color: #124659;
    color: $yellow;

    &:hover {
      color: $red;
    }
  }
}